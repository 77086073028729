import { useState } from 'react';
import Head from 'next/head';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  TypographyProps,
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
  Lock,
  AccountCircle,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import getLocalizationProps from '@core/localization';
import readyToValidation from '@helpers/validation';
import ILoginDTO from '@modules/auth/types/ILoginDTO';
import { Login } from '@modules/auth/api';
import { CheckRegisterFailureExists } from '@modules/technic/api';
import { dispatcher } from '@redux/store';
import { setAppFailureAction, setAppFailureInternalAction, setAppLoggedUserAction } from '@redux/app-actions';
import PermissionControl from '@modules/auth/helpers/PermissionControl';
import Routes from '@src/routes';

const WAIT_MS = 1000 * 60 * 5;

const StyledMainBox = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#0B1419',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: `url(${process.env.NEXT_PUBLIC_BASE_PATH}images/login-bg.png)`,
});

const StyledOverlayImage = styled(Box)({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  backgroundSize: 'auto',
  backgroundPosition: 'center',
  backgroundImage: `url(${process.env.NEXT_PUBLIC_BASE_PATH}images/light-bg.png)`,
});

const StyledTypography = styled((props: TypographyProps) => (
  <Typography {...props} variant="h2" />
))({
  fontSize: '2.5rem',
  fontWeight: '800',
  color: '#fff',
});

const SyledFormGroup = styled(Box)({
  marginTop: 34,
  width: '100%',
});

const formControl = {
  width: '100%',
  color: '#fff',
  borderRadius: '5px',
  backgroundColor: '#121212',
  border: '1px solid rgba(158, 66, 76, 0.25)',
  boxShadow: '0px 4px 4px rgba(158, 66, 76, 0.25)'
};

const StyledFormControl = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    ...formControl,
    '& input': {
      color: '#fff',
    },
    '&:hover fieldset': {
      borderColor: '#9E424C',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
  '& .MuiFormHelperText-root': {
    color: 'red',
  },
});

const StyledCopyright = styled('p')({
  color: '#fff',
  position: 'absolute',
  left: 10,
  bottom: 10,
  fontSize: '1rem',
  margin: 0,
});

export default function Index() {
  const router = useRouter();
  const { t } = useTranslation(['login', 'validation']);

  const [passwordVisiblity, setPasswordVisiblity] = useState(false);
  const { register, formState: { errors }, handleSubmit } = useForm<ILoginDTO>();

  const handleVisibilityPassword = () => setPasswordVisiblity(!passwordVisiblity);

  const onSubmit = async (data: ILoginDTO) => {
    Login(data).then((response) => {
      if (response.success) {
        const { userInfo } = response.result;
        dispatcher(setAppLoggedUserAction(userInfo));

        if (PermissionControl.Control(userInfo.roles, 'Admin|Tech|TeamLead')) {
          CheckRegisterFailureExists().then((res) => {
            if (res.result) {
              dispatcher(setAppFailureAction(res.result));
            }
          });
          const interval = setInterval(() => {
            CheckRegisterFailureExists().then((res) => {
              if (res.result) {
                dispatcher(setAppFailureAction(res.result));
              }
            });
          }, WAIT_MS);
          dispatcher(setAppFailureInternalAction(interval));
        }

        if (PermissionControl.Control(userInfo.roles, 'Admin|Manager')) {
          router.push(Routes.index.path);
        } else {
          router.push(Routes.businesses.path);
        }
      }
    });
  };

  const fields = {
    username: t('username') as string,
    password: t('password') as string,
  };

  const validation = {
    username: readyToValidation(fields.username, {
      required: true,
      minLength: 2,
      maxLength: 50,
    }),
    password: readyToValidation(fields.password, {
      required: true,
      minLength: 6,
      maxLength: 50,
    }),
  };

  return (
    <StyledMainBox>
      <Head>
        <title>OPS!YON - Login</title>
      </Head>
      <StyledOverlayImage />
      <Box sx={{ textAlign: 'center', zIndex: 2 }}>
        <StyledTypography>OPS!YON</StyledTypography>
        <StyledTypography>MANAGEMENT</StyledTypography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SyledFormGroup>
            <StyledFormControl
              fullWidth
              hiddenLabel
              {...register('Username', validation.username)}
              type="text"
              placeholder={fields.username}
              helperText={errors.Username?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle sx={{ color: '#fff' }} />
                  </InputAdornment>
                ),
              }}
            />
          </SyledFormGroup>
          <SyledFormGroup>
            <StyledFormControl
              type={passwordVisiblity ? 'text' : 'password'}
              fullWidth
              hiddenLabel
              {...register('Password', validation.password)}
              placeholder={fields.password}
              helperText={errors.Password?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock sx={{ color: '#fff' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {passwordVisiblity ? (
                      <Visibility
                        onClick={handleVisibilityPassword}
                        sx={{ color: '#fff', cursor: 'pointer' }}
                      />
                    ) : (
                      <VisibilityOff
                        onClick={handleVisibilityPassword}
                        sx={{ color: '#fff', cursor: 'pointer' }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </SyledFormGroup>
          <Button
            type="submit"
            variant="contained"
            sx={{
              mt: 4,
              ...formControl,
              '&:hover': {
                bgcolor: '#0B1419',
              },
            }}
          >
            {t('login')}
          </Button>
        </form>
      </Box>
      <StyledCopyright>
        Copyright © Milvasoft Yazılım Bilişim Ar-Ge Dan. San. ve Tic. Ltd.ŞTİ.
      </StyledCopyright>
    </StyledMainBox>
  );
}

export const getServerSideProps = getLocalizationProps(['login', 'validation']);
