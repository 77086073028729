import Api from '@lib/api';
import ICachedRegisterFailureDTO from '../types/ICachedRegisterFailureDTO';

/**
 * Updates tenants database.
 */
export function UpdateSpecificDatabases(data: Array<string>) {
  return Api.Put<string>({
    url: '/Opsiyon/Databases',
    headers: { 'Content-Type': 'application/json' },
    data,
  });
}

/**
 * Updates all tenants database. Returns log messages.
 */
export function UpdateDatabases() {
  return Api.Put<string>({
    url: '/Opsiyon/Databases/All'
  });
}

/**
 * Updates tenants database.
 */
export function RefreshTenantStore() {
  return Api.Delete({
    url: '/Opsiyon/TenantStore/Refresh'
  });
}

/**
 * Checks for a successful checkout but failed registration. The interface is also used to send notifications to Milvasoft employees.
 */
export function CheckRegisterFailureExists() {
  return Api.Get<boolean>({
    url: '/Opsiyon/Failures/Check/Register',
    successMessaging: false,
    loading: false,
  });
}

/**
 * Returns successful payment but error in registration process.
 */
export function GetFailedRegistrations() {
  return Api.Get<Array<ICachedRegisterFailureDTO>>({
    url: '/Opsiyon/Failures',
    successMessaging: false
  });
}

/**
 * Returns successful payment but error in registration process. Query string can be tenancy name or sended value in failure mail.
 */
export function GetFailedRegistration(queryString: string) {
  return Api.Get<ICachedRegisterFailureDTO>({
    url: `/Opsiyon/Failures/Failure/${queryString}`,
    successMessaging: false
  });
}

/**
 * Mark as solved failure. Query string can be tenancy name or sended value in failure mail.
 */
export function MarkAsSolvedRegisterationFailure(queryString: string) {
  return Api.Delete({
    url: `/Opsiyon/Failures/Failure/${queryString}`
  });
}

/**
 * Add ip to blacklist.
 */
export function AddToBlackList(ip: string) {
  return Api.Post({
    url: `/DeveloperOps/Blacklist/${ip}`
  });
}

/**
 * Add ip to blacklist.
 */
export function GetBlacklists() {
  return Api.Get<Array<{ip: string; sendCount: number}>>({
    url: '/DeveloperOps/Blacklist',
    successMessaging: false
  });
}

/**
 * Remove ip from black list.
 */
export function RemoveFromVerificationBlackList(ip: string) {
  return Api.Delete({
    url: `/DeveloperOps/Blacklist/${ip}`
  });
}
